import {
  ref, watch, onUnmounted, getCurrentInstance,
} from '@vue/composition-api';
import store from '@/store';
import toast from '@/utils/toast';
import vehicleStoreModule from '../vehicleStoreModule';

export default function useVehicleList() {
  const STORE_MODULE_NAME = 'vehicle';

  // Register module
  if (!store.hasModule(STORE_MODULE_NAME)) {
    store.registerModule(STORE_MODULE_NAME, vehicleStoreModule);
  }

  // UnRegister on leave
  onUnmounted(() => {
    if (store.hasModule(STORE_MODULE_NAME)) {
      store.unregisterModule(STORE_MODULE_NAME);
    }
  });
  // Use toast
  const toastification = toast();

  const blankItem = {
    type: null,
    name: '',
    color: '',
    licensePlate: '',
    ticketNumber: '',
    ownerName: '',
    apartment: null,
    room: null,
    tenant: null,
    image: '',
    imageFile: null,
  };

  const item = ref(JSON.parse(JSON.stringify(blankItem)));
  const resetItem = () => {
    item.value = JSON.parse(JSON.stringify(blankItem));
  };

  const vm = getCurrentInstance().proxy;
  const isHidden = !vm.$can('update', 'vehicle') && !vm.$can('delete', 'vehicle');

  // Table Handlers
  const columns = [
    {
      label: 'Mã',
      field: 'code',
    },
    {
      label: 'Thao tác',
      field: 'action',
      tdClass: 'text-center',
      sortable: false,
      width: '100px',
      hidden: isHidden,
    },
    {
      label: 'Loại xe',
      field: 'type.title',
    },
    {
      label: 'Biển số xe',
      field: 'licensePlate',
    },
    {
      label: 'Khách hàng',
      field: 'tenant.name',
    },
    {
      label: 'Tòa nhà',
      field: 'apartment.name',
    },

  ];
  const rows = ref([]);
  // filter
  const apartment = ref(null);
  const room = ref(null);
  const tenant = ref(null);
  const type = ref(null);
  // current selected rows
  const selectedRows = ref([]);
  const searchTerm = ref('');
  // ssr
  const isLoading = ref(false);
  const totalRecords = ref(0);
  const serverParams = ref({
    searchTerm: '',
    filter: {},
    sort: {},
    page: 1,
    perPage: 20,
  });

  //   API Call
  const fetchVehicles = () => {
    isLoading.value = true;
    store
      .dispatch('vehicle/fetchVehicles', serverParams.value)
      .then(response => {
        const { total, items } = response.data;
        totalRecords.value = total;
        rows.value = items;
      })
      .catch(error => {
        toastification.showToastError(error);
      });
  };

  const deleteVehicles = vehicles => {
    store
      .dispatch('vehicle/deleteVehicles', {
        ids: vehicles.map(_obj => _obj.id),
      })
      .then(() => {
        fetchVehicles();
        toastification.showToastDeleteSuccess();
      })
      .catch(error => {
        toastification.showToastError(error);
      });
  };

  const downloadImportTemplate = () => {
    store
      .dispatch('vehicle/generateImportVehicleTemplate')
      .then(response => {
        window.location.href = response.data;
      })
      .catch(error => {
        toastification.showToastError(error);
      });
  };

  const importData = data => {
    const formData = new FormData();
    formData.append('file', data.file);
    store
      .dispatch('vehicle/importVehicles', formData)
      .then(() => {
        fetchVehicles();
        toastification.showToastCreateSuccess();
        vm.$router
          .push({ name: 'jobs' });
      })
      .catch(error => {
        toastification.showToastError(error);
      });
  };

  const exportData = () => {
    store
      .dispatch('vehicle/exportVehicles')
      .then(response => {
        window.location.href = response.data;
      })
      .catch(error => {
        toastification.showToastError(error);
      });
  };

  //   Methods
  const updateParams = newProps => {
    serverParams.value = { ...serverParams.value, ...newProps };
    fetchVehicles();
  };

  const onPageChange = params => {
    updateParams({ page: params.currentPage });
  };

  const onPerPageChange = params => {
    updateParams({ perPage: params.currentPerPage });
  };

  const onSortChange = params => {
    updateParams({
      sort: {
        type: params[0].type,
        field: params[0].field,
      },
    });
  };

  const onColumnFilter = params => {
    updateParams(params);
  };

  const selectionChanged = params => {
    selectedRows.value = params.selectedRows;
  };

  const onEditItem = val => {
    item.value = val;
  };

  //   Watch

  watch(apartment, val => {
    const { filter } = serverParams.value;
    if (val) {
      filter.apartmentId = val.id;
    } else {
      delete filter.apartmentId;
    }
    updateParams({ filter });
  });
  watch(room, val => {
    const { filter } = serverParams.value;
    if (val) {
      filter.roomId = val.id;
    } else {
      delete filter.roomId;
    }
    updateParams({ filter });
  });
  watch(tenant, val => {
    const { filter } = serverParams.value;
    if (val) {
      filter.tenantId = val.id;
    } else {
      delete filter.tenantId;
    }
    updateParams({ filter });
  });
  watch(type, val => {
    const { filter } = serverParams.value;
    if (val) {
      filter.typeId = val.value;
    } else {
      delete filter.typeId;
    }
    updateParams({ filter });
  });

  watch(searchTerm, val => {
    updateParams({ searchTerm: val });
  });

  return {
    item,
    columns,
    rows,
    apartment,
    room,
    tenant,
    type,
    selectedRows,
    searchTerm,
    isLoading,
    totalRecords,
    serverParams,

    fetchVehicles,
    deleteVehicles,
    resetItem,
    onEditItem,
    updateParams,
    onPageChange,
    onPerPageChange,
    onSortChange,
    onColumnFilter,
    selectionChanged,
    importData,
    exportData,
    downloadImportTemplate,
  };
}
